import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import axios from 'axios'
import router from './router'
import http from '../utils/http'
import formatDate from '../utils/currency'
import store from './store/index'
import Print from 'vue-print-nb'
import './assets/fonts/font.css'
// import EasyRing from 'easy-ring'
import './components/auto/index'
// import { Message } from 'element-ui';


// Vue.prototype.$message = function(msg) {
//   return Message({
//     message: msg,
//     duration: 100
//   });
// };
// // 分别对success、warning和error等样式进行设置
// Vue.prototype.$message.success = function(msg) {
//   return Message.success({
//     message: msg,
//     duration: 1500
//   });
// };
// Vue.prototype.$message.warning = function(msg) {
//   return Message.warning({
//     message: msg,
//     duration: 100
//   });
// };
// Vue.prototype.$message.error = function(msg) {
//   return Message.error({
//     message: msg,
//     duration: 500
//   });
// };


// Vue.use(EasyRing)
Vue.use(ElementUI);
Vue.use(Print); //注册

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$http = http


//004账套
// Vue.prototype.$qitaurl = 'https://rhsp.top:44313/'
// Vue.prototype.$Savefile = 'https://rhsp.top:44313/api/Common/SaveSinglefile'


//015账套
Vue.prototype.$qitaurl = 'https://rhsp.top:44318/'
Vue.prototype.$Savefile = 'https://rhsp.top:44318/api/Common/SaveSinglefile'


//003账套
// Vue.prototype.$qitaurl = 'https://rhsp.top:44321/'
// Vue.prototype.$Savefile = 'https://rhsp.top:44321/api/Common/SaveSinglefile'




Vue.prototype.$imgUrl = 'https://rhsp.top:9999/'


// Vue.prototype.$printIp = 'http://127.0.0.1:9997/'

Vue.prototype.$printIp = 'http://localhost:9997/'



// Vue.prototype.$chengchenIp = "ws://192.168.0.125:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.218:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.111:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.110:5000"
Vue.prototype.$chengchenIp = "ws://127.0.0.1:5000"
// Vue.prototype.$chengchenIp = "ws://localhost:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.121:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.222:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.101:5000"
// Vue.prototype.$chengchenIp = "ws://192.168.0.120:5000"


//测试版
// Vue.prototype.$Savefile = 'http://122.9.12.12:44313/api/Common/SaveSinglefile'
// Vue.prototype.$imgUrl = 'http://122.9.12.12:8111/'

//临时版
// Vue.prototype.$Savefile = 'https://rhsp.top:44311/api/Common/SaveSinglefile'
// Vue.prototype.$imgUrl = 'https://rhsp.top:8899/'

Vue.prototype.$formatD = formatDate.formatDate
Vue.prototype.$formatHMS = formatDate.formatHMS
Vue.prototype.$timeChange = formatDate.TimeChange
Vue.prototype.$hmsChange = formatDate.hmsChange
Vue.prototype.$ymdChange = formatDate.ymdChange
Vue.prototype.$checkPrice = formatDate.checkPrice

new Vue({
	router,
	store,
	render: h => h(App),
	beforeCreate() {
		Vue.prototype.$bus = this // 安装全局事件总线  $bus
	}
}).$mount('#app')
