import {
	request
} from './request'

// get
function expor(url, params) {
	return request({
		method: 'POST',
		url: url,
		params
	})
}

// get
function get(url, params) {
	return request({
		method: 'GET',
		url: url,
		params
	})
}


// post
function post(url, data) {
	return request({
		method: 'POST',
		url: url,
		data
	})
}

// del
function del(url, data) {
	return request({
		method: 'DELETE',
		url: url,
		data
	})
}

//put
function put(url,data){
	return request({
		method:'PUT',
		url:url,
		data
	})
}

export default {
	get,
	post,
	del,
	expor,
	put
}
