<template>
	<div class="">
		<div class="headBox flex_float">
			<!-- <div class="flex">
				<img class="logo" src="@/assets/logo.jpg" alt="">
				<h1 class="title">荣辉后台管理系统</h1>
			</div> -->
			<div class="name">{{username}}</div>
			<el-button @click="outLogin" type="danger">退出</el-button>
			<!-- <button @click="btn">提示音</button> -->
		</div>
		<!-- <easy-ring :open="open" :ring.sync="ring" :src="yourAudio" /> -->
	</div>
</template>

<script>
	// import { EasyRingVueComponent as EasyRing } from 'easy-ring'
	// import yourAudio from '@/assets/vue.mp3'
	export default {
		// components: {
		// 	EasyRing
		// },
		name: 'topBox',
		inject: ['reload'],
		data() {
			return {
				username: '',
				open: false,
				ring: true
			}
		},
		watch:{
			$route(to, from){
				this.getTime()
			},
			
		},
		mounted() {
			
		},
		methods: {
			getTime(){
				let nowDate = this.$ymdChange(new Date())
				if(sessionStorage.getItem('nowTime')!=nowDate){
					this.$message.error('身份失效，请重新登录');
					this.$router.push({
						name: 'login',
					})
				}
			},
			btn() {
				let that = this
				setInterval(function(){
					that.$http.post('api/services/app/BnsOrder/MsgWarnNew').then(res=>{
						if(res){
							const audio = new Audio(yourAudio);
							audio.addEventListener('canplaythrough', () => {
								audio.play();
							});
						}
					})
				},3000)
			},
			outLogin() {
				this.$confirm("是否退出产品到登录界面?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					sessionStorage.removeItem('token')
					sessionStorage.clear()
					// this.reload()
					window.location.reload();
					this.$router.push({
						name: 'login'
					})
				}).catch(() => {
					this.$message({ 
						duration:1000,
						type: 'info',
						message: '已取消'
					});
				});
				
			},
			
		},
		created() {
			// ''this.username = sessionStorage.getItem('loguserName')
			// if(this.username == '库管'){
			// 	this.btn()
			// }''
			// this.btn()
			this.getTime()
		},
	}
</script>

<style lang="scss" scoped>
	.headBox {
		position: fixed;
		top: 0;
		right: 0;
		width: 30px;
		z-index: 9999;
		// padding: 10px;
		// height: 40px;
		// background-color: #fff;
	}

	.name {
		margin: auto 10px auto auto;
	}

	.logo {
		width: 50px;
		height: 50px;
	}

	.title {
		color: #000c17;
	}
</style>
