// 导入
import Vue from 'vue'
import Vuex from 'vuex'
// 安装使用
Vue.use(Vuex);
// 第一种方式导出
export default new Vuex.Store({
  state: {
	  routePath:''
  },
  mutations: {
	  editRoutePath(state,string){
		  state.routePath = string
	  }
  },
  actions: {
  },
  modules: {
  }
})
// 第二种，任选一种
// const store = new Vuex.Store({
	// state:{
		// count:1,
		// msg:'这里是state的初始化数据。'
	// }
// })
// export default store;
