import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '@/iview/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'login',
		icon: 'el-icon-question',
		meta: {
			title: '登录页'
		},
		type: '',
		type: '',
		component: () => import('@/iview/login/login.vue')
	},
	{
		path: '/',
		name: 'home',
		icon: 'el-icon-user',
		meta: {
			title: '首页'
		},
		// redirect:'/home',
		type: '',
		component: () => import('@/iview/Home.vue')
	},
	{
		path: '/homepa',
		name: 'homepa',
		meta: {
			title: '首页'
		},
		type: '',
		icon: 'el-icon-s-fold',
		component: main,
		children: [{
				path: 'homepage',
				meta: {
					title: '首页',
					keepAlive:true
				},
				code:'0001',
				name: 'homepage',
				type: '',
				component: () => import('@/iview/homepage.vue')
			},
		]
	},
	{
		path: "/baseData",
		name: "baseData",
		meta: {
			title: "基础数据"
		},
		type: '',
		icon: "el-icon-data-analysis",
		component: main,
		children: [
			{
				path: 'userQuery',
				meta: {
					title: '客户管理',
					keepAlive:false
				},
				code:'0701',
				name: 'userQuery',
				type: '',
				component: () => import('@/iview/system/userQuery.vue')
			},
			{
				path: 'staffmanagement',
				meta: {
					title: '员工管理',
					keepAlive:false
				},
				code:'0707',
				name: 'staffmanagement',
				type: '',
				component: () => import('@/iview/system/staff_management.vue')
			},
			{
				path: 'userRole',
				meta: {
					title: '权限分组',
					keepAlive:false
				},
				code:'0702',
				name: 'userRole',
				type: '',
				component: () => import('@/iview/system/userRole/list.vue')
			},
			{
				path: 'depositType',
				meta: {
					title: '押金类型',
					keepAlive:true
				},
				code:'0102',
				name: 'depositType',
				type: '',
				component: () => import('@/iview/baseData/depositType.vue')
			},
			{
				path: 'goodsArea',
				meta: {
					title: '备货区',
					keepAlive:false
				},
				code:'0103',
				name: 'goodsArea',
				type: '',
				component: () => import('@/iview/baseData/goodsArea.vue')
			},
			{
				path: 'tareFile',
				meta: {
					title: '皮重档案',
					keepAlive:false
				},
				code:'0105',
				name: 'tareFile',
				type: '',
				component: () => import('@/iview/baseData/tareFile.vue')
			},
			{
				path: 'warehouseFile',
				meta: {
					title: '仓库档案',
					keepAlive:false
				},
				code:'0106',
				name: 'warehouseFile',
				type: '',
				component: () => import('@/iview/baseData/warehouseFile.vue')
			},
			{
				path: 'settlement',
				meta: {
					title: '结算方式档案',
					keepAlive:false
				},
				code:'0107',
				name: 'settlement',
				type: '',
				component: () => import('@/iview/baseData/settlement.vue')
			},
			{
				path: 'projectSorting',
				meta: {
					title: '项目一键分拣',
					keepAlive:false
				},
				code:'0108',
				name: 'projectSorting',
				type: '',
				component: () => import('@/iview/baseData/projectSorting.vue')
			},
			// {
			// 	path: 'supplierManagement',
			// 	meta: {
			// 		title: '供应商管理',
			// 		keepAlive:true
			// 	},
			// 	code:'0104',
			// 	name: 'supplierManagement',
			// 	type: '',
			// 	component: () => import('@/iview/baseData/supplierManagement.vue')
			// },
		]
	},
	{
		path: '/mallManagement',
		name: 'mallManagement',
		meta: {
			title: '商城管理'
		},
		type: '',
		icon: 'el-icon-s-cooperation',
		component: main,
		children: [
			// {
			// 	path: 'classification',
			// 	meta: {
			// 		title: '商品分类',
			// 		keepAlive:true
			// 	},
			// 	code:'0201',
			// 	name: 'classification',
			// 	type: '',
			// 	component: () => import('@/iview/mallManagement/classification.vue')
			// },
			{
				path: 'customer',
				meta: {
					title: '客户个性化',
					keepAlive:false
				},
				code:'0202',
				name: 'customer',
				type: '',
				component: () => import('@/iview/mallManagement/customer.vue')
			},
			{
				path: 'manage',
				meta: {
					title: '商品管理列表',
					keepAlive:true
				},
				code:'0203',
				name: 'manage',
				type: '',
				component: () => import('@/iview/mallManagement/manage/list.vue')
			},
			{
				path: 'getProductData',
				type: 'hidden',
				meta: {
					title: '拉取商品数据',
					keepAlive:false
				},
				code:'0204',
				name: 'getProductData',
				type: '',
				component: () => import('@/iview/mallManagement/manage/getProductData.vue')
			},
			{
				path: 'channelClassification',
				type: 'hidden',
				meta: {
					title: '渠道分类',
					keepAlive:false
				},
				code:'0205',
				name: 'channelClassification',
				type: '',
				component: () => import('@/iview/mallManagement/channelClassification.vue')
			},
			{
				path: 'virtualQuantity',
				type: 'hidden',
				meta: {
					title: '首页虚拟数量',
					keepAlive:false
				},
				code:'0206',
				name: 'virtualQuantity',
				type: '',
				component: () => import('@/iview/mallManagement/virtualQuantity.vue')
			},
			{
				path: 'synchronized',
				type: 'hidden',
				meta: {
					title: 'T+存货未同步',
					keepAlive:false
				},
				code:'0207',
				name: 'synchronized',
				type: '',
				component: () => import('@/iview/mallManagement/synchronized.vue')
			}
		]
	},
	{
		path: '/order',
		name: 'order',
		meta: {
			title: '订单管理'
		},
		type: '',
		icon: 'el-icon-s-order',
		component: main,
		children: [
			{
				path: 'quick',
				type: 'hidden',
				meta: {
					title: '快捷下单',
					keepAlive:true
				},
				code:'0301',
				name: 'quick',
				type: '',
				component: () => import('@/iview/order/quick_update.vue')
			},
			{
				path: 'saleReturn',
				type: 'hidden',
				meta: {
					title: '销售退货',
					keepAlive:true
				},
				code:'0305',
				name: 'saleReturn',
				type: '',
				component: () => import('@/iview/order/saleReturn.vue')
			},
			{
				path: 'selection',
				meta: {
					title: '现场选货',
					keepAlive:true
				},
				code:'0403',
				name: 'selection',
				type: '',
				component: () => import('@/iview/weighing/siteSelection_update.vue')
			},
			// {
			// 	path: 'selectionTwo',
			// 	meta: {
			// 		title: '现场选货2',
			// 		keepAlive:true
			// 	},
			// 	code:'04032',
			// 	name: 'selectionTwo',
			// 	type: '',
			// 	component: () => import('@/iview/weighing/siteSelection_new_update.vue')
			// },
			{
				path: 'retail',
				meta: {
					title: '零售单',
					keepAlive:true
				},
				code:'0307',
				name: 'retail',
				type: '',
				component: () => import('@/iview/order/retail_update.vue')
			},
			{
				path: 'supplement',
				meta: {
					title: '补单',
					keepAlive:true
				},
				code:'0311',
				name: 'supplement',
				type: '',
				component: () => import('@/iview/order/supplement.vue')
			},
			{
				path: 'orderList',
				meta: {
					title: '订单列表',
					keepAlive:true
				},
				code:'0302',
				name: 'orderList',
				type: '',
				component: () => import('@/iview/order/order/list.vue')
			},
			{
				path: 'returnOrderList',
				meta: {
					title: '退货订单列表',
					keepAlive:true
				},
				code:'0306',
				name: 'returnOrderList',
				type: '',
				component: () => import('@/iview/order/returnOrderList.vue')
			},
			{
				path: 'printrecord',
				meta: {
					title: '打印记录',
					keepAlive:false
				},
				code:'0309',
				name: 'printrecord',
				type: '',
				component: () => import('@/iview/order/print_record.vue')
			},
			{
				path: 'logging',
				meta: {
					title: '日志记录',
					keepAlive:false
				},
				code:'0310',
				name: 'logging',
				type: '',
				component: () => import('@/iview/order/logging.vue')
			},
			{
				path: 'returnOrderUpdate',
				meta: {
					title: '退货订单修改',
					keepAlive:true
				},
				code:'',
				name: 'returnOrderUpdate',
				type: '',
				component: () => import('@/iview/order/saleReturnUpdate.vue')
			},
			{
				path: 'order_detail_list',
				meta: {
					title: '订单明细',
					keepAlive:true
				},
				code:'0308',
				name: 'order_detail_list',
				type: '',
				component: () => import('@/iview/order/order_detail_list.vue')
			},
			// {
			// 	path: 'salespersonList',
			// 	meta: {
			// 		title: '销售员订单列表',
			// 		keepAlive:true
			// 	},
			// 	code:'0303',
			// 	name: 'salespersonList',
			// 	type: '',
			// 	component: () => import('@/iview/order/order/list_salesperson.vue')
			// },
			// {
			// 	path: 'pickerList',
			// 	meta: {
			// 		title: '拣货员订单列表',
			// 		keepAlive:true
			// 	},
			// 	code:'0304',
			// 	name: 'pickerList',
			// 	type: '',
			// 	component: () => import('@/iview/order/order/list_picker.vue')
			// },retutnDetail
			{
				path: 'retutnDetail',
				meta: {
					title: '退货订单详情',
					keepAlive:false
				},
				name: 'retutnDetail',
				// type: 'side',
				component: () => import('@/iview/order/retutnDetail.vue')
			},
			{
				path: 'orderDetail',
				meta: {
					title: '订单详情',
					keepAlive:false
				},
				name: 'orderDetail',
				// type: 'side',
				component: () => import('@/iview/order/order/detail.vue')
			},
			{
				path: 'record',
				meta: {
					title: '订单记录',
					keepAlive:false
				},
				name: 'record',
				type: 'side',
				component: () => import('@/iview/order/order/record.vue')
			},
			{
				path: 'print',
				meta: {
					title: '条码补打',
					keepAlive:false
				},
				name: 'print',
				type: 'side',
				component: () => import('@/iview/order/print.vue')
			},
			{
				path: 'totalOrder',
				meta: {
					title: '总单打印详情',
					keepAlive:false
				},
				name: 'totalOrder',
				type: 'side',
				component: () => import('@/iview/order/totalOrder.vue')
			},
		]
	},
	{
		path: '/task',
		name: 'task',
		meta: {
			title: '任务管理'
		},
		type: '',
		icon: 'el-icon-s-fold',
		component: main,
		children: [{
				path: 'taskList',
				meta: {
					title: '任务列表',
					keepAlive:true
				},
				code:'0501',
				name: 'taskList',
				type: '',
				component: () => import('@/iview/task/task/list.vue')
			},
			{
				path: 'staff',
				meta: {
					title: '员工统计',
					keepAlive:false
				},
				code:'0502',
				name: 'staff',
				type: '',
				component: () => import('@/iview/task/staff.vue')
			},
			{
				path: 'task_detail_list',
				meta: {
					title: '任务明细',
					keepAlive:false
				},
				code:'0503',
				name: 'task_detail_list',
				type: '',
				component: () => import('@/iview/task/task_detail_list.vue')
			},
			
		]
	},
	{
		path: '/weighing',
		name: 'weighing',
		meta: {
			title: '称重管理'
		},
		type: '',
		icon: 'el-icon-s-management',
		component: main,
		children: [{
				path: 'weighingFloatingRange',
				meta: {
					title: '称重浮动区间',
					keepAlive:false
				},
				code:'0401',
				name: 'weighingFloatingRange',
				type: '',
				component: () => import('@/iview/weighing/weighingFloatingRange.vue')
			},
			{
				path: 'picking',
				meta: {
					title: '分拣称重',
					keepAlive:false
				},
				code:'0402',
				name: 'picking',
				type: '',
				component: () => import('@/iview/weighing/picking_new.vue')
				// component: () => import('@/iview/weighing/picking_old_test.vue')
			},
			{
				path: 'weighing_detail_list',
				meta: {
					title: '称重明细',
					keepAlive:false
				},
				code:'0404',
				name: 'weighing_detail_list',
				type: '',
				component: () => import('@/iview/weighing/weighing_detail_list.vue')
			},
		]
	},
	{
		path: '/collectionManagement',
		name: 'collectionManagement',
		meta: {
			title: '收款管理'
		},
		type: '',
		icon: 'el-icon-s-management',
		component: main,
		children: [{
				path: 'collection',
				meta: {
					title: '收款单',
					keepAlive:true
				},
				code:'0801',
				name: 'collection',
				type: '',
				component: () => import('@/iview/collection/list_new.vue')
			},
			{
				path: 'collectionList',
				meta: {
					title: '收款列表',
					keepAlive:true
				},
				code:'0802',
				name: 'collectionList',
				type: '',
				component: () => import('@/iview/collection/list_collection.vue')
			},
			{
				path: 'collection_detail',
				meta: {
					title: '收款详情',
					keepAlive:false
				},
				code:'',
				name: 'collection_detail',
				type: '',
				component: () => import('@/iview/collection/list_detail.vue')
			},
			{
				path: 'detail_list',
				meta: {
					title: '收款明细',
					keepAlive:false
				},
				code:'0803',
				name: 'detail_list',
				type: '',
				component: () => import('@/iview/collection/detail_list.vue')
			},
			{
				path: 'uncollected',
				meta: {
					title: '未收款',
					keepAlive:false
				},
				code:'0804',
				name: 'uncollected',
				type: '',
				component: () => import('@/iview/collection/uncollected.vue')
			},
			{
				path: 'reconciliation',
				meta: {
					title: '对账',
					keepAlive:false
				},
				code:'0805',
				name: 'reconciliation',
				type: '',
				component: () => import('@/iview/collection/reconciliation.vue')
			},
		]
	},
	
	
	{
		path: '/basket',
		name: 'basket',
		meta: {
			title: '周转筐管理'
		},
		type: '',
		icon: 'el-icon-menu',
		component: main,
		children: [{
				path: 'basketStorageManage',
				meta: {
					title: '周转筐档案',
					keepAlive:false
				},
				code:'0601',
				name: 'basketStorageManage',
				type: '',
				component: () => import('@/iview/basket/basketStorageManage.vue')
			},
			// {
			// 	path: 'basketInOutManage',
			// 	meta: {
			// 		title: '周转筐出入库',
			// 		keepAlive:true
			// 	},
			// 	code:'0602',
			// 	name: 'basketInOutManage',
			// 	type: '',
			// 	component: () => import('@/iview/basket/basketInOutManage.vue')
			// },
			{
				path: 'currentInventory',
				meta: {
					title: '当前库存',
					keepAlive:false
				},
				code:'0603',
				name: 'currentInventory',
				type: '',
				component: () => import('@/iview/basket/currentInventory.vue')
			},
			{
				path: 'specification',
				meta: {
					title: '出入库明细表',
					keepAlive:false
				},
				code:'0604',
				name: 'specification',
				type: '',
				component: () => import('@/iview/basket/specification.vue')
			},
			{
				path: 'purchaseReceipt',
				meta: {
					title: '采购入库',
					keepAlive:true
				},
				code:'0605',
				name: 'purchaseReceipt',
				type: '',
				component: () => import('@/iview/basket/purchaseReceipt.vue')
			},
			{
				path: 'purchaseRetrun',
				meta: {
					title: '采购退货',
					keepAlive:true
				},
				code:'0606',
				name: 'purchaseRetrun',
				type: '',
				component: () => import('@/iview/basket/purchaseRetrun.vue')
			},
			{
				path: 'borrow',
				meta: {
					title: '借入',
					keepAlive:true
				},
				code:'0607',
				name: 'borrow',
				type: '',
				component: () => import('@/iview/basket/borrow.vue')
			},
			{
				path: 'borrowPayback',
				meta: {
					title: '借入归还',
					keepAlive:true
				},
				code:'0608',
				name: 'borrowPayback',
				type: '',
				component: () => import('@/iview/basket/borrowPayback.vue')
			},
			{
				path: 'lend',
				meta: {
					title: '借出',
					keepAlive:true
				},
				code:'0609',
				name: 'lend',
				type: '',
				component: () => import('@/iview/basket/lend.vue')
			},
			{
				path: 'lendPayback',
				meta: {
					title: '借出归还',
					keepAlive:true
				},
				code:'0610',
				name: 'lendPayback',
				type: '',
				component: () => import('@/iview/basket/lendPayback.vue')
			},
			{
				path: 'gain',
				meta: {
					title: '盘点',
					keepAlive:false
				},
				code:'0612',
				name: 'gain',
				type: '',
				component: () => import('@/iview/basket/gain.vue')
			},
			{
				path: 'lendingAndOutstandingStatistics',
				meta: {
					title: '借出未还统计',
					keepAlive:false
				},
				code:'0615',
				name: 'lendingAndOutstandingStatistics',
				type: '',
				component: () => import('@/iview/basket/lendingAndOutstandingStatistics.vue')
			},
			{
				path: 'borrowedButNotReturnedStatistics',
				meta: {
					title: '借入未还统计',
					keepAlive:false
				},
				code:'0616',
				name: 'borrowedButNotReturnedStatistics',
				type: '',
				component: () => import('@/iview/basket/borrowedButNotReturnedStatistics.vue')
			},
			{
				path: 'basketDetail',
				meta: {
					title: '详情',
					keepAlive:false
				},
				code:'0617',
				name: 'basketDetail',
				type: '',
				component: () => import('@/iview/basket/detail.vue')
			},
		]
	},
	
	{
		path: '/system',
		name: 'system',
		meta: {
			title: '系统管理'
		},
		type: '',
		icon: 'el-icon-s-tools',
		component: main,
		children: [
			// {
			// 	path: 'formatMaintenance',
			// 	meta: {
			// 		title: '打印模板',
			// 		keepAlive:false
			// 	},
			// 	code:'0101',
			// 	name: 'formatMaintenance',
			// 	type: '',
			// 	component: () => import('@/iview/baseData/formatMaintenance.vue')
			// },
			
			// {
			// 	path: 'generalOrder',
			// 	meta: {
			// 		title: '总单模板',
			// 		keepAlive:false
			// 	},
			// 	code:'0703',
			// 	name: 'generalOrder',
			// 	type: '',
			// 	component: () => import('@/iview/system/generalOrder.vue')
			// },
			// {
			// 	path: 'subcontract',
			// 	meta: {
			// 		title: '分包模板',
			// 		keepAlive:false
			// 	},
			// 	code:'0704',
			// 	name: 'subcontract',
			// 	type: '',
			// 	component: () => import('@/iview/system/subcontract.vue')
			// },
			{
				path: 'credit',
				meta: {
					title: '售价及信用',
					keepAlive:true
				},
				code:'0705',
				name: 'credit',
				type: '',
				component: () => import('@/iview/system/credit.vue')
			},
			{
				path: 'inventory_control',
				meta: {
					title: '盘点管控',
					keepAlive:true
				},
				code:'0706',
				name: 'inventory_control',
				type: '',
				component: () => import('@/iview/system/inventory_control.vue')
			},
			{
				path: 'print_settings',
				meta: {
					title: '打印设置',
					keepAlive:true
				},
				code:'0707',
				name: 'print_settings',
				type: '',
				component: () => import('@/iview/system/print_settings.vue')
			},
		]
	}
	
]

const router = new VueRouter({
	mode: 'hash',
	routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
	// to将要访问的路径
	// from代表从哪个路径跳转而来
	// next是一个函数，表示放行
	// next() 放行 next('/login) 强制跳转
	if (to.path === '/login') return next() // 登录页不需要做权限控制，直接放行
	// 访问的是一个有权限的页面，先拿到token，根据是否有token再决定是否发生强制跳转
	// 获取token
	const tokenStr = sessionStorage.getItem('token')
	if (!tokenStr) {
		// messageTips (vue.$message, '请先登录', 'warning')
		return next('/login') // 不存在token
	}
	// 存在token，直接放行
	next()
})

export default router
