<template>
	<div>
		<div>
			<el-popover  v-model="visible" placement="bottom-start"
			 width="240" trigger="focus">
				<el-row>
					<el-col :span="18">
						<el-row>
							 <el-col v-for="(item,index) in btnshuru" :key="item+'asfafdaf'" :span="8" > <div @click="handleShuRu(item)" class="shuzibox">{{item}}</div> </el-col>
						</el-row>
					</el-col>
					<el-col :span="6">
						<div @click="handleShuRu('删除')" class=" clearbtn h80">
								清除
						</div>
						<div @click="visible = false" class=" shuziboxbtn h80 textcen"> {{'确认'}} </div>
					</el-col>
				</el-row>
				<el-input :disabled="disabled" autocomplete="off" auto-complete="new-password" class="inputlh" 
				slot="reference" @input="handleInput"  :placeholder="placeholder"  ref="keyboardRef"
				v-model="num" :clearable="clearable" >
				</el-input>
			</el-popover>
		</div>
		<div>
			
		</div>
	</div>
</template>

<script>
	export default{
		name:'keyboardNumber',
		props:{
			// ref:{
			// 	type:String,
			// 	default:'refInput'
			// },
			disabled:{
				type:Boolean,
				default:false
			},
			clearable:{
				type:Boolean,
				default:false
			},
			value:{
				type:[String,Number],
				default:null
			},
			placeholder:{
				type:[String],
				default:''
			}
		},
		data() {
			return{
				visible:false,
				btnshuru:[1,2,3,4,5,6,7,8,9,'.',0,'00'],
				num:this.value || null
			}
		},
		computed:{},
		watch:{
			value(val){
				this.num = val
			}
		},
		methods:{
			handleShuRu(e){
				if(this.disabled){
					return
				}
				this.num = this.num + ''
				if(e == '删除'){
					console.log('-----this.num----------------',this.num)
					this.num = this.num.slice(0,this.num.length-1)
				}else{
					this.num += e
					this.num = this.$checkPrice(this.num)
				}
				this.$emit('input', this.num);
			},
			handleInput(){
				this.num = this.$checkPrice(this.num)
				this.$emit('input', this.num);
				// console.log('------this.num-----------------',this.num)
			}
		},
		mounted() {
			
		},
		created() {
			
		}
	}
</script>

<style lang="scss" scoped>
	.divBox /deep/.el-input__inner{
		font-size: 30px;
	}
	.shuzibox{
		font-size: 30px;
		padding: 10px;
		width: 40px;
		border: 1px solid #eee;
		text-align: center;
		line-height: 40px;
		height: 40px;
	}
	.shuzibox:active{
		background-color: #F56C6C;
		color: #fff;
	}
	.shuziboxbtn{
		font-size: 20px;
		background-color: #F56C6C;
		color: #fff;
		border-bottom: 1px solid #F56C6C;
		padding: 1px 0;
		margin-left: 2px;
	}
	.clearbtn{
		font-size: 20px;
		background-color: #fff;
		color: #000;
		text-align: center;
		border: 1px solid #eee;
		padding: 1px 0;
	}
	.h80{
		line-height: 120px;
		height: 120px;
	}
</style>