<template>
	<div id="app">
		<router-view v-if="isReload"></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		provide() {
			return {
				reload: this.reload
			}
		},
		data() {
			return {
				isReload: true,
				ip:''
			}
		},

		components: {},
		mounted() {
			window.addEventListener("beforeunload", e => {
				this.unloadFn(e);
			});
		},
		destroyed() {
			window.removeEventListener("beforeunload", e => {
				this.unloadFn(e);
			});
		},
		methods: {
			unloadFn(e) {
				// this.$router.push({name:this.$route.name})
			},
			reload() {
				this.isReload = false
				this.$nextTick(() => {
					this.isReload = true
				})
			},
		}
	}
</script>

<style>
	html,
	body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		font-family: ARIAL;
	}
	/deep/#el-popover{
		z-index: 900;
	}

	/deep/.el-form-item {
		margin-bottom: 5px;
	}

	/deep/.el-table .el-table__cell {
		padding: 0;
	}

	/deep/.el-table .cell.el-tooltip {
		padding: 0;
	}

	/deep/.el-table th.el-table__cell>.cell {
		padding: 0 5px;
		color: #000;
	}

	.textalign {
		text-align: center;
	}

	.relative {
		position: relative;
	}

	.colorbtn {
		color: #409EFF;
		cursor: pointer;
	}

	.w45 {
		width: 45px;
	}

	.w70 {
		width: 70px;
	}

	.mtb10 {
		margin: 10px 0;
	}

	.mb10 {
		margin-bottom: 10px;
	}

	.ptb10 {
		padding: 10px 0;
	}

	.messageIndex {
		z-index: 9999 !important;
	}

	.el-message {
		z-index: 9999 !important;
	}

	.line2 {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.noDataText {
		text-align: center;
		padding: 100rpx;
		font-size: 32rpx;
		color: #333;
	}

	.textR {
		text-align: right;
	}

	.textC {
		text-align: center;
	}

	.row {
		display: flex;
		align-items: center;
	}

	.flex {
		display: flex;
	}

	.clearBoth {
		clear: both;
	}

	.floatL {
		float: left;
	}

	.floatR {
		float: right;
	}

	.width100 {
		width: 100% !important;
	}

	.widthAuto {
		width: auto;
	}

	.textcen {
		text-align: center;
	}

	.img {
		width: 100%;
		height: 100%;
	}

	.mr10 {
		margin-right: 10px;
	}

	.bold {
		font-weight: bold;
	}

	.fz12 {
		font-size: 12px;
	}

	.fz14 {
		font-size: 14px;
	}

	.fz16 {
		font-size: 16px;
	}

	.fz18 {
		font-size: 18px;
	}

	.fz20 {
		font-size: 20px;
	}
	.fz24 {
		font-size: 24px;
	}
	.fz28 {
		font-size: 28px;
	}
	.fz30 {
		font-size: 30px;
	}

	.page_content {
		display: block;
		box-sizing: border-box;
		width: 100%;
		/* padding: 20px; */
	}

	.flex_float {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.middle_center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.colorfff {
		color: #fff;
	}

	.color0 {
		color: #000;
	}

	.color3 {
		color: #333;
	}

	.color9 {
		color: #999;
	}

	.colorF5 {
		color: #F59A23;
	}

	.bold {
		font-weight: bold;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		/* font-family: 'SimSun'; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;

	}

	.line1 {
		overflow: hidden;
		text-overflow: ellipsis;
		/* 超出部分省略号 */
		word-break: break-all;
		/* break-all(允许在单词内换行。) */
		display: -webkit-box;
		/* 对象作为伸缩盒子模型显示 */
		-webkit-box-orient: vertical;
		/* 设置或检索伸缩盒对象的子元素的排列方式 */
		-webkit-line-clamp: 1;
		/* 显示的行数 */
	}
</style>