<template>
	<div>
		<el-container>
			<el-aside :width="asideWidth?'64px':'200px'" height="100%">
				<Menu></Menu>
			</el-aside>
			<el-container>
				<el-header style="padding: 0 10px 0 0;height: 40px;position: relative;" class="flex" >
					<div v-if="tabList.length!=0" class="tabbox">
						<el-tabs  v-model="tabIndex" @tab-click="handleTab" type="card" closable 
						@tab-remove="removeTab">
							<el-tab-pane v-for="(item, index) in tabList" :key="item.name+item.title+index" :label="item.title"
								:name="item.name">
							</el-tab-pane>
						</el-tabs>
					</div>
					<div class="flex" style="height: 40px;position: absolute;top: 0px;right: 10px;" >
						<div class="" style="line-height: 40px;" >
							{{loginTime}} &emsp;
						</div>
						<el-popover style="min-width: 10px;" v-model="visible" placement="bottom" width="60"  trigger="click">
							<div class="colorbtn" @click="passvisible = true" >修改密码</div>
							<div slot="reference" style="line-height: 40px;" class="name">{{username}}</div>
						</el-popover>
						<el-button @click="outLogin" size="mini" type="danger">退出</el-button>
					</div>
				</el-header>
				<el-main style="height: 100%;">
					<el-card style="min-height: 99%;" >
						<!-- <el-button @click="reset"  type="primary">刷新</el-button> -->
						<div v-if="isRefresh"  class="relative" >
							<keep-alive>
								<router-view ref="currRoutePage" :key="$route.path"  
								v-if="$route.meta.keepAlive&&nameList.indexOf($route.meta.title)>-1"></router-view>
							</keep-alive>
							<router-view :key="key"  v-if="!$route.meta.keepAlive||nameList.indexOf($route.meta.title)==-1"></router-view>
						</div>
					</el-card>
				</el-main>
			</el-container>
		</el-container>
		<el-dialog title="修改密码" :visible.sync="passvisible" width="30%" :close-on-click-modal="false" 
		destroy-on-close
		:before-close="handleSettleClose">
				<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="原密码" prop="currentPassword">
						<el-input type="password" v-model="ruleForm.currentPassword" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="pass">
						<el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="newPassword">
						<el-input type="password" v-model="ruleForm.newPassword" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="passvisible = false">取 消</el-button>
				<el-button :loading="isSubTwo" type="danger" @click="submitSettle('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
		<easy-ring :open="open" :ring.sync="ring" :src="yourAudio" />
	</div>
</template>

<script>
	import { EasyRingVueComponent as EasyRing } from 'easy-ring'
	import Head from '@/iview/head/head.vue'
	import Menu from '@/iview/menu/menu.vue'
	import yourAudio from '@/assets/cangku.mp3'
	export default {
		components: {
			Head,
			Menu,
			EasyRing
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					if (this.ruleForm.newPassword !== '') {
						this.$refs.ruleForm.validateField('newPassword');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				tabList: [
					// {title: "快捷下单",name: "quick",content:"/mallManagement/quick"}
				],
				ruleForm: {
					pass: '',
					currentPassword: '',
					newPassword: '',
				},
				yourAudio:yourAudio,
				open: false,
				ring: true,
				isRefresh: true,
				// nameList: ['快捷下单'],
				nameList: [],
				sideList:['record','print','totalOrder','login'],
				tabIndex:'',
				loginTime:sessionStorage.getItem('orderTime'),
				username: '',
				userrole: '',
				asideWidth:false,
				visible:false,
				passvisible:false,
				isSubTwo:false,
				rules: {
					pass: [
						{required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					newPassword: [{required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
					currentPassword:[{required: true,}]
				},
			}
		},
		watch:{
			$route(to, from){
				this.getTime()
				let route = this.$route
				let data = this.tabList
				if(!this.sideList.includes(route.name)){
					let obj = {
						title:route.meta.title,
						name:route.name,
						content:route.path,
					}
					this.tabIndex = route.name
					if(!this.nameList.includes(route.meta.title)){
						this.nameList.push(route.meta.title)
						data.push(obj)
					}
					this.tabList = data
					sessionStorage.setItem('home_tab_list',JSON.stringify(this.tabList))
					sessionStorage.setItem('home_name_list',JSON.stringify(this.nameList))
				}
			},
		},
		computed: {
			key() {
				return this.$route.path + Math.random();
			}
		},
		mounted() {
			this.$bus.$on('isCollapse',(data)=>{
					this.asideWidth = data
			})
			let data = sessionStorage.getItem('home_tab_list')
			if(data!=null){
				this.tabList = JSON.parse(data)
				this.nameList = JSON.parse(sessionStorage.getItem('home_name_list'))
			}
			this.tabIndex = this.$route.name
		},
		methods: {
			reset(){
				this.isRefresh = false
				let that = this
				setTimeout(function(){
					that.isRefresh = true
				},500)
			},
			
			handleSettleClose(){
				this.passvisible = false
				this.ruleForm = {
					pass: '',
					currentPassword: '',
					newPassword: '',
				}
				this.isSubTwo = false
			},
			submitSettle(formName){
				this.isSubTwo = true
				this.$refs[formName].validate((valid) => {
				  if (valid) {
					this.$http.post('api/services/app/User/ChangePassword',this.ruleForm).then(res=>{
						this.$message({ 
						duration:1000,
							type: 'success',
							message: '修改成功'
						});
						this.isSubTwo = false
						this.passvisible = false
						this.$router.push({
							name: 'login'
						})
					})
				  } else {
					let that = this
					setTimeout(function(){
						that.isSubTwo = false
					},1000)
					return false;
				  }
				});
			},
			outLogin() {
				this.$confirm("是否退出产品到登录界面?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					sessionStorage.removeItem('token')
					sessionStorage.clear()
					// this.reload()
					window.location.reload();
					this.$router.push({
						name: 'login'
					})
				}).catch(() => {
					this.$message({ 						duration:1000,
						type: 'info',
						message: '已取消'
					});
				});
				// sessionStorage.removeItem('token')
				// sessionStorage.clear()
				// // this.reload()
				// window.location.reload();
				// this.$router.push({
				// 	name: 'login'
				// })
			},
			removeTab(targetName){
				// if(targetName == 'quick'){return}
				let i = 0
				let routername = ''
				this.tabList.forEach((item,index)=>{
					if(item.name == targetName){
						i = index
						routername = item.content
					}
				})
				this.tabList.splice(i,1)
				this.nameList.splice(i,1)
				sessionStorage.setItem('home_tab_list',JSON.stringify(this.tabList))
				sessionStorage.setItem('home_name_list',JSON.stringify(this.nameList))
				console.log('--targetName---------',targetName)
				if(targetName == 'picking'){
					sessionStorage.removeItem('pickingtableftval')
				}
				console.log('--this.tabIndex---------',this.tabIndex)
				console.log('--this.tabList--------',this.tabList)
				if(targetName == this.tabIndex){
					if(i != 0){
						this.$router.push({
							name: this.tabList[i-1].name,
						})	
					}else{
						if(this.tabList.length == 0){
							this.$router.push({
								name: 'homepa',
							})
						}else{
							this.$router.push({
								name: this.tabList[i].name,
							})
						}
					}
				}
				this.handleDelHC(routername)
			},
			handleDelHC(routername){
				if(this.$refs.currRoutePage){
					const {cache, keys} = this.$refs.currRoutePage.$options.parent
					// console.log('-----',cache,keys, keys.indexOf(routername))
					if(keys.indexOf(routername)>-1){
						delete cache[routername]
						keys.splice(keys.indexOf(routername), 1)
					}
				}
			},
			handleTab(e){
				// if(e.name == this.tabIndex){
				// 	this.$message({
				// 		duration:1000,
				// 		type: 'warning',
				// 		message: '你已在当前页面'
				// 	});
				// 	return
				// }
				this.$router.push({name:e.name})
				this.tabIndex = e.name
				this.$store.commit('editRoutePath',e.path)
			},
			getTime(){
				let nowDate = this.$ymdChange(new Date())
				if(sessionStorage.getItem('nowTime')!=nowDate){
					this.$message.error('身份失效，请重新登录');
					this.$router.push({
						name: 'login',
					})
				}
			},
			btn() {
				let that = this
				setInterval(function(){
					let userid = sessionStorage.getItem('loguserId')
					that.$http.post('api/services/app/BnsOrder/MsgWarnNew?userId=' + userid ).then(res=>{
						if(res){
							const audio = new Audio(yourAudio);
							audio.addEventListener('canplaythrough', () => {
								audio.play();
							});
						}
					})
				},3000)
			},
			btnxs() {
				let that = this
				setInterval(function(){
					let userid = sessionStorage.getItem('loguserId')
					that.$http.post('api/services/app/BnsOrder/MsgWarnOrdListNew?userId=' + userid ).then(res=>{
						if(res){
							const audio = new Audio(yourAudio);
							audio.addEventListener('canplaythrough', () => {
								audio.play();
							});
						}
					})
				},3000)
			},
		},
		created() {
			// setInterval(function(){
				
			// },3000)
			this.username = sessionStorage.getItem('loguserName')
			this.userrole = sessionStorage.getItem('loginuserrole')
			let islingshengtrue =  sessionStorage.getItem('islingshengtrue')
			if(islingshengtrue){
				if(this.userrole&&(this.userrole.indexOf('库管')>-1)&&(islingshengtrue == 1)){
					this.btn()
				}
			}
			if(this.userrole&&this.userrole.indexOf('销售')>-1){
				this.btnxs()
			}
			this.getTime()
		},
	}
</script>


<style lang="scss">
	html,body {
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		// font-family: 'ARIAL';
	}
	/deep/.el-card__body {
		height: 95%;
		// font-family: 'ARIAL';
	}

	.mb30{
		margin-bottom: 30px;
	}
	.name {
		margin: auto 10px auto auto;
	}
	.tabbox{
		width: 80%;
		padding-left: 10px;
		height: 40px;
		background-color: #fff;
		// position: absolute;
		z-index: 999;
	}
	.el-container {
		height: 100vh;
		overflow: hidden;
	}

	.el-main {
		background-color: #efefef;
		padding: 10px;
	}
	/deep/.el-table th.el-table__cell>.cell {
		padding: 0 5px;
		color: #000;
	}
	.el-popover{
		min-width: 50px!important;
	}
</style>
