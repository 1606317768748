<template>
	<div class="menuBox">
		<div class="flex" 
		style="align-items: center;position:fixed;top:0px;left:0px;z-index:990;background-color:#fff;width:200px;" >
			<img class="logo" src="@/assets/logo.jpg" alt="">
			<div class="title bold color0 fz16">荣辉后台管理系统</div>
		</div>
		<el-menu router unique-opened :default-active="defaultActive" class="el-menu-vertical-demo" @open="handleOpen"
			@close="handleClose" :collapse="isCollapse" background-color="#001529" text-color="#fff">
				<div style="margin-top: 40px;" >
					<i v-if="isCollapse" @click="isCollapse = !isCollapse" class="el-icon-s-fold isColl"></i>
					<i v-if="!isCollapse" @click="isCollapse = !isCollapse" class="el-icon-s-unfold isColl"></i>
				</div>
			<el-submenu v-if="item.children" :index="item.path" v-for="(item, index) in  menuList" 
			:key="item.name">
				<template slot="title">
					<i :class="item.icon"></i>
					<span>{{ item.meta.title }}</span>
				</template>
				<!-- <el-menu-item  v-if="data.type != 'side' && premission.includes(data.code)" :route="data" :index="data.path" v-for="(data, i) in  item.children" :key="data.name">&emsp;&emsp; {{ data.meta.title }}</el-menu-item> -->
				<el-menu-item v-if="data.type != 'side'" :route="data" :index="data.path"
					v-for="(data, i) in  item.children" :key="data.name">
					<!-- <el-badge v-if="data.path=='taskList'&&taskNum!=0&&username == '库管'" :value="taskNum" :max="99"> -->
					<el-badge v-if="data.path=='uncollected'&&uncollectedNum!=0" 
					:value="uncollectedNum" :max="99">
					  <span>&emsp;&ensp; {{ data.meta.title }}</span>
					</el-badge>
					<span v-else>&emsp;&ensp; {{ data.meta.title }}</span>
				</el-menu-item>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menuList: [],
				premission: [],
				username:'',
				isCollapse:false,
				taskNum:0,
				uncollectedNum:0,
				defaultActive:this.$router.name,
			}
		},
		  //监听getSome事件
		  mounted() {
		       this.$bus.$on('getSome',(data)=>{
					this.getTaskNum()
		       })
			  
			   let that = this
			   let username = sessionStorage.getItem('loguserName')
			   this.username = username
			   if(username == '库管'){
			   	 setInterval(function(){
			   	 	that.getTaskNum()
			   	 },3000)
			   }
			   
			  
			   setTimeout(() => {
				 this.defaultActive = this.$route.name
			   }, 100)
		   },
		    //解绑getSome事件
		  // beforeDestroy() {
		  //      this.$bus.$off('getSome');
		  //   },

		methods: {
			getTaskNum(){
				this.$http.post('api/services/app/BnsTask/TaskNoStart').then(res=>{
					this.taskNum = res
				})
			},
			handleOpen(key, keyPath) {
				// console.log('--------', key, '=++++++++++++++', keyPath);
			},
			handleClose(key, keyPath) {
				// console.log('===========', key, keyPath);
			}
		},
		watch:{
			$route(to, from){
				let route = this.$route
				this.defaultActive = route.name
			},
			isCollapse(value){
				this.$bus.$emit('isCollapse',this.isCollapse);
			}
		},
		created() {
			this.getTaskNum()
			let routers = this["$router"].options.routes; // 获取路由对象routes
			let data = []
			let premission = sessionStorage.getItem('grantedPermissions')
			this.premission = premission
			let that = this
			if(this.premission.indexOf('0804')>-1){
			   setInterval(function(){
					that.$http.get('api/services/app/BnsOrder/GetUnSkPCAllCount').then(res=>{
						that.uncollectedNum = res
					})
			   },3000)
			}
			if(premission!=null){
				premission = JSON.parse(premission)
			}
			if(!premission){
				this.menuList = []
				return
			}
			routers.forEach((item,index)=>{
				if(item.name!="login"&&item.name!="home"){
					item.children = item.children.filter(e => premission.includes(e.code))
					if(item.children.length>=1){
						data.push(item)
					}
				}
			})
			this.menuList = data
		},
	}
</script>

<style lang="scss" scoped>
	.menuBox {
		height: 100vh;
		overflow-y: auto;
	}
	.menuBox::-webkit-scrollbar{display: none;}

	.el-menu-vertical-demo {
		height: 100vh;
	}

	.el-menu-item.is-active {
		color: #fff;
		background-color: #2d8cf0 !important;
	}
	.isColl{
		text-align: center;
		color: #fff;
		margin-left: 20px;
		margin-top: 20px;
		font-size: 20px;
		
	}
	
	.logo {
		width: 40px;
		height: 40px;
	}
	
	.title {
		color: #000c17;
		height: 40px;
		line-height: 40px;
	}
</style>
