import axios from 'axios';
import {
	Notification,
	MessageBox,
	Loading,
	Message
} from 'element-ui';
// request是一个axios实例,每一个实例你都可以单独定制它的baseURL,超时时间和一些其他配置项。
// const pro = 'https://rhsp.top:44313/'   //004
// const dev = 'http://122.9.12.12:44313/'   //测试版
// const devp = 'https://rhsp.top:44311/'   //临时版
const devnew = 'https://rhsp.top:44318/'   //015
// const devnew = 'https://rhsp.top:44321/' //015
const request = axios.create({
	baseURL: devnew,
	timeout: '5000'
})

const token = sessionStorage.getItem('token')
// 请求拦截器,就是说请求在到达服务器之前,你对发送到服务器的数据进行一些处理,比如
// 后端说除了登录之外的接口都要在请求头上面带上token,你就可以在这里处理
request.interceptors.request.use(
	(config) => {

		//比如是否需要设置 token
		config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
		config.headers['Content-Type'] = 'application/json';
		config.data = JSON.stringify(config.data)
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
);
let timer = null
// 响应拦截器,后端数据真正被你的变量接收之前,进行一些预处理,比如对于一些5,4开头的状态码进行统一处理
request.interceptors.response.use(
	(config) => {
		if (!config.data.success) {
			return Promise.reject(config.data)
		} else {
			return config.data.result;
		}
	},
	(error) => {
		// Notification.error({
		// 	title: error.response.data.error.message
		// })

		try {
			MessageBox.confirm(error.response.data.error.message, '系统提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'error',
				customClass: 'messageIndex'
			})
			return Promise.reject(error.response.data.error)
		} catch (e) {
			// if (!timer) { //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
			// 	timer = setTimeout(() => { //关
			// 		MessageBox.confirm('请求失败', '系统提示', {
			// 			confirmButtonText: '确认',
			// 			cancelButtonText: '取消',
			// 			type: 'error',
			// 			customClass: 'messageIndex'
			// 		})
			// 		timer = null; //开
			// 	}, 100)
			// }
			return Promise.reject('请求失败')
		}
		// Message({
		// 	message: error.response.data.error.message,
		// 	type: 'error',
		// 	center: true
		// })

	}
)
export {
	request
}
