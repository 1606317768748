function formatDate(row, column) {
	// 获取单元格数据
	let data = row[column.property];
	if (data == null) {
		return null;
	}
	let dt = new Date(data)
	return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt
		.getMinutes() + ':' + dt.getSeconds()
}
function checkPrice(value){
	let checkPlan = '' + value
	checkPlan = checkPlan
	  .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
	  .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
	  .replace(/^\./g, '') // 保证第一个为数字而不是.
	  .replace('.', '$#$')
	  .replace(/\./g, '')
	  .replace('$#$', '.')
	if (checkPlan.indexOf('.') < 0 && checkPlan !== '') {
	  // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
	  checkPlan = parseFloat(checkPlan) + ''
	} else if (checkPlan.indexOf('.') >= 0) {
	  checkPlan = checkPlan
	    .replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
	}
	return checkPlan
}
function formatHMS(row, column) {
	// 获取单元格数据
	let data = row[column.property];
	if (data == null) {
		return null;
	}
	let dt = new Date(data)
	return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
}

function TimeChange(e) {
	if(!e){
		return
	}
	var date = new Date(e);
	var y = date.getFullYear(); // 年
	var m = date.getMonth() + 1; // 月
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate(); // 日
	d = d < 10 ? ('0' + d) : d;
	var h = date.getHours(); // 时
	h = h < 10 ? ('0' + h) : h;
	var min = date.getMinutes(); // 分
	min = min < 10 ? ('0' + min) : min;
	var s = date.getSeconds(); // 秒
	s = s < 10 ? ('0' + s) : s;
	let time = y + '-' + m + '-' + d +' ' + h + ':' + min + ':' + s;//拼在一起
	return time
}
function hmsChange(e) {
	var date
	if(!e){
		date = new Date()
	}
	date = new Date(e);
	var y = date.getFullYear(); // 年
	var m = date.getMonth() + 1; // 月
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate(); // 日
	d = d < 10 ? ('0' + d) : d;
	var h = date.getHours(); // 时
	h = h < 10 ? ('0' + h) : h;
	var min = date.getMinutes(); // 分
	min = min < 10 ? ('0' + min) : min;
	var s = date.getSeconds(); // 秒
	s = s < 10 ? ('0' + s) : s;
	let time = h + ':' + min + ':' + s;//拼在一起
	return time
}
function ymdChange(e) {
	var date
	if(!e){
		date = new Date()
	}
	var date = new Date(e);
	var y = date.getFullYear(); // 年
	var m = date.getMonth() + 1; // 月
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate(); // 日
	d = d < 10 ? ('0' + d) : d;
	var h = date.getHours(); // 时
	h = h < 10 ? ('0' + h) : h;
	var min = date.getMinutes(); // 分
	min = min < 10 ? ('0' + min) : min;
	var s = date.getSeconds(); // 秒
	s = s < 10 ? ('0' + s) : s;
	let time = y + '-' + m + '-' + d//拼在一起
	return time
}

export default {
	hmsChange,
	ymdChange,
	formatDate,
	formatHMS,
	checkPrice,
	TimeChange
}
